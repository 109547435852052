
.custom-swiper .swiper-pagination {
  bottom: 10px; 
  right: 10px; 
  left: auto; 
  width: auto; 
}

.custom-swiper .swiper-pagination-bullet {
  background-color: white; 
  opacity: 0.5; 
  transition: opacity 0.3s; 
}

.custom-swiper .swiper-pagination-bullet-active {
  background-color: white; 
  opacity: 1; 
}
