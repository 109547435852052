
html {
  scroll-behavior: smooth;
}

.w-full {
  position: relative;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.title-container {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.title {
  font-size: 2rem; /* Adjust the size as needed */
  font-weight: bold;
}

.subtitle {
  font-size: 1rem; /* Adjust the size as needed */
}

@media (max-width: 768px) {
  .title {
    font-size: 86px; /* Adjust the size for mobile as needed */
  }

  .subtitle {
    font-size: 48px; /* Adjust the size for mobile as needed */
  }

  .title-container {
    position: absolute;
    top: 25%;
    left: 49%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
}
